/**
 * 
 * Plugin for Microsoft Gateway API Login
 * running with @azure/msal-browser: ^3.13.0
 * 
 */

import { PublicClientApplication } from '@azure/msal-browser'

export default defineNuxtPlugin(async () => {
    const runtimeConfig = useRuntimeConfig()

    const config = {
        auth:
        {
            clientId: runtimeConfig.public.clientId,
            authority: 'https://login.microsoftonline.com/organizations/',
            redirectUri: runtimeConfig.public.redirectUri,
        }
    }

    const msal = new PublicClientApplication(config)
    await msal.initialize()

    const login = async () => {
        let loginRequest = {
            scopes: ['user.read'],
        }
        try {
            let loginResponse = await msal.loginPopup(loginRequest)
            return loginResponse
        }
        catch (err) {
            console.log(err);
        }
    }

    let tokenResponse

    const acquireTokenSilent = async () => {
        const account = msal.getAllAccounts()

        if (account.length > 0) {
            let tokenRequest = {
                scopes: ['user.read'],
                account: account[0]
            }
            tokenResponse = await msal.acquireTokenSilent(tokenRequest)
            return tokenResponse
        }
        else {
            return null
        }
    }

    const logout = async () => {
        const token = await acquireTokenSilent()
        const homeAccountId = token.account.homeAccountId
        const currentAccount = msal.getAccount(homeAccountId)

        await msal.logoutPopup({
            account: currentAccount
        })
    }

    return {
        provide: {
            login,
            acquireTokenSilent,
            logout
        }
    }
})